import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import produce from 'immer';
import getConfig from 'next/config';

import MESSAGES from '~/components/store/storeMessage';
import { brazeEvent, showLoading, hideLoading, showToast } from '~/utils/postMessage';

import fetcher from '~/api/lib/fetcher';
import { HttpError } from '~/types/Error';
import { convertError } from '~/utils/converter';

import { getV2AuthHeaders, getBrazeCartOrderAllObject } from '~/utils/common';

const { publicRuntimeConfig } = getConfig();
const { V2_API } = publicRuntimeConfig;

// Actions
const FETCH_ORDER_LIST_REQUEST = 'FETCH_ORDER_LIST_REQUEST';
const FETCH_ORDER_LIST_SUCCESS = 'FETCH_ORDER_LIST_SUCCESS';
const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
const FETCH_ORDER_CANCEL_REQUEST = 'FETCH_ORDER_CANCEL_REQUEST';
const FETCH_ORDER_CANCEL_SUCCESS = 'FETCH_ORDER_CANCEL_SUCCESS';
const FETCH_ORDER_CANCEL_ALL_REQUEST = 'FETCH_ORDER_CANCEL_ALL_REQUEST';
const FETCH_ORDER_CANCEL_ALL_SUCCESS = 'FETCH_ORDER_CANCEL_ALL_SUCCESS';
const ORDER_LIST_RESET = 'ORDER_LIST_RESET';

const fetchOrderListRequest = createAction(FETCH_ORDER_LIST_REQUEST);
const fetchOrderListSuccess = createAction(FETCH_ORDER_LIST_SUCCESS);
const fetchOrderFailure = createAction(FETCH_ORDER_FAILURE);
const fetchOrderCancelRequest = createAction(FETCH_ORDER_CANCEL_REQUEST);
const fetchOrderCancelSuccess = createAction(FETCH_ORDER_CANCEL_SUCCESS);
const fetchOrderCancelAllRequest = createAction(FETCH_ORDER_CANCEL_ALL_REQUEST);
const fetchOrderCancelAllSuccess = createAction(FETCH_ORDER_CANCEL_ALL_SUCCESS);
const orderListReset = createAction(ORDER_LIST_RESET);

export { fetchOrderListRequest, fetchOrderCancelRequest, fetchOrderCancelAllRequest };

const initialState = {
  pending: false,
  data: {
    washId: 0,
    orders: [],
    totalOrderPrice: 0,
    totalQuantity: 0,
    totalOriginalPrice: 0,
    totalAccumulatePoint: 0,
    totalDiscountPrice: 0,
    totalSellPrice: 0,
    isAvailableAllOrderCancel: true,
    refiendSkuBrandList: [],
  },
  error: null,
};

export const reducer = handleActions(
  {
    [FETCH_ORDER_LIST_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.data = initialState.data;
        draft.error = null;
      }),
    [FETCH_ORDER_LIST_SUCCESS]: (state, { payload: { c, d } }: any) =>
      produce(state, (draft) => {
        if (c > 0) {
          const { message } = d;
          throw new HttpError(c, message);
        }

        const { orders } = d;

        if (orders.length === 0) {
          draft.pending = false;
          draft.data = initialState.data;
          draft.error = null;
          return;
        }

        let orderItems: any[] = [];
        let isAvailableAllOrderCancel = true;

        const totalAssets = orders.reduce(
          (acc, current) => {
            const retutnAcc = {
              ...acc,
            };

            orderItems = [...orderItems, ...current.orderItems];

            const additionalQuantity = current.orderItems.reduce((iacc, icurr) => {
              if (isAvailableAllOrderCancel && icurr.availableOrderCancelYn === 'N') {
                isAvailableAllOrderCancel = false;
              }

              return iacc + (icurr.quantity - icurr.cancelQuantity);
            }, 0);

            retutnAcc.totalDiscountPrice += current.discountPrice;
            retutnAcc.totalFlatRateDiscountPrice += current.flatRateDiscountPrice;
            retutnAcc.totalQuantity += additionalQuantity;

            retutnAcc.totalOriginalPrice += current.originalPrice;
            retutnAcc.totalAccumulatePoint += current.accumulatePoint;

            return retutnAcc;
          },
          {
            totalDiscountPrice: 0,
            totalFlatRateDiscountPrice: 0,
            totalQuantity: 0,
            totalOriginalPrice: 0,
            totalAccumulatePoint: 0,
          }
        );

        const refiendSkuBrandList = orderItems.reduce((acc, curr) => {
          if (acc.length === 0) {
            return [
              {
                productBrandName: curr.productBrand,
                list: [curr],
              },
            ];
          }

          const indexByProductBrandName = acc.findIndex(
            (prev) => prev.productBrandName === curr.productBrand
          );

          if (indexByProductBrandName > -1) {
            acc[indexByProductBrandName].list.push(curr);
            return acc;
          }
          return [
            ...acc,
            {
              productBrandName: curr.productBrand,
              list: [curr],
            },
          ];
        }, []);

        draft.pending = false;
        draft.data = {
          ...d,
          ...totalAssets,
          totalDiscountPrice: totalAssets.totalDiscountPrice,
          totalSellPrice: d.totalOrderPrice + totalAssets.totalFlatRateDiscountPrice,
          totalFlatRateDiscountPrice: totalAssets.totalFlatRateDiscountPrice,
          isAvailableAllOrderCancel,
          refiendSkuBrandList,
        };
        draft.error = null;
      }),
    [FETCH_ORDER_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = convertError(error);
      }),
    [FETCH_ORDER_CANCEL_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.error = null;
      }),
    [FETCH_ORDER_CANCEL_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = null;
      }),
    [FETCH_ORDER_CANCEL_ALL_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.pending = true;
        draft.error = null;
      }),
    [FETCH_ORDER_CANCEL_ALL_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.pending = false;
        draft.error = null;
      }),
    [ORDER_LIST_RESET]: (state, { payload: { washId } }: any) =>
      produce(state, (draft) => {
        draft.data = {
          ...initialState.data,
          washId,
        };
      }),
  },
  initialState
);

function* watchFetchOrderListSaga(action) {
  showLoading();

  const { headers } = yield select((state) => state.http);
  const { washId } = action.payload;

  try {
    const url = `${V2_API}/v2/products/order/wash/${washId}`;

    const result = yield call(fetcher.get, url, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });
    yield put(fetchOrderListSuccess({ ...result.data }));
  } catch (error) {
    yield put(fetchOrderFailure(error));
  } finally {
    hideLoading();
  }
}

function* watchFetchOrderCancelSaga(action) {
  showLoading();

  const { headers } = yield select((state) => state.http);
  const { washId } = yield select((state) => state.store.orderList.data);
  const { orderItemId, currentItem } = action.payload;

  try {
    const url = `${V2_API}/v2/products/order/items/${orderItemId}/cancel`;
    yield call(fetcher.delete, url, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });
    yield put(fetchOrderCancelSuccess());
    yield call(watchFetchOrderListSaga, {
      payload: {
        washId,
      },
    });
    showToast(MESSAGES.ORDER.CALCEL_SUCCESS);

    brazeEvent({
      eventName: 'item_order_canceled',
      eventValue: {
        item_id: String(currentItem.productId),
        item_name: currentItem.productName,
        item_category: currentItem.categoryName,
        item_price: `${currentItem.orderPrice / currentItem.quantity}`,
        item_tag: currentItem.tags.join(','),
        item_image_url: currentItem.representUrls[0],
        item_brand_name: currentItem.productBrand,
        total_number_of_item: currentItem.quantity,
        total_item_price: currentItem.orderPrice,
      },
    });
  } catch (error) {
    yield put(fetchOrderFailure(error));
  } finally {
    hideLoading();
  }
}

function* watchFetchOrderCancelAllSaga() {
  showLoading();

  const { headers } = yield select((state) => state.http);
  const { washId, orders, totalSellPrice, totalQuantity } = yield select(
    (state) => state.store.orderList.data
  );

  try {
    const url = `${V2_API}/v2/products/order/wash/${washId}/cancel`;
    yield call(fetcher.delete, url, {
      headers: {
        ...getV2AuthHeaders(headers),
      },
    });
    yield put(fetchOrderCancelAllSuccess());
    yield put(
      orderListReset({
        washId,
      })
    );
    showToast(MESSAGES.ORDER.CALCEL_SUCCESS);

    const orderList = orders.reduce((a, c) => [...a, ...c.orderItems], []);
    const brazeMatchingKeys = {
      item_id: 'productId',
      item_name: 'productName',
      item_category: 'categoryName',
      item_price: 'sellPrice',
      item_tag: 'tags',
      item_image_url: 'representUrls',
      item_brand_name: 'productBrand',
    };
    const brazeEventValues = getBrazeCartOrderAllObject({
      type: 'ORDER',
      dataArray: orderList,
      brazeMatchingKeys,
      totalQuantity,
      totalSellPrice,
    });
    brazeEvent({
      eventName: 'item_order_canceled',
      eventValue: brazeEventValues,
    });
  } catch (error) {
    yield put(fetchOrderFailure(error));
  } finally {
    hideLoading();
  }
}

export const sagas = [
  takeLatest<any>(FETCH_ORDER_LIST_REQUEST, watchFetchOrderListSaga),
  takeLatest<any>(FETCH_ORDER_CANCEL_REQUEST, watchFetchOrderCancelSaga),
  takeLatest<any>(FETCH_ORDER_CANCEL_ALL_REQUEST, watchFetchOrderCancelAllSaga),
];
