import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

// eslint-disable-next-line import/prefer-default-export
export const GlobalStyle = createGlobalStyle`
	${normalize};
	a {
		text-decoration: none;
	}
	body > iframe {
		display: none;
	}

	body {
		font-family: 'Pretendard', sans-serif, '돋움', Dotum, Arial;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
	}

	.ReactModal__Body--open {
		overflow: hidden;
		outline: 0;
		-webkit-overflow-scrolling: touch;
	}

	input {
		-webkit-appearance: none;
		border-radius: 0;
		-webkit-tap-highlight-color: transparent;
	}

	button {
		outline: 0;
	}

	div,
	span {
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
	}

    @font-face {
        font-family: 'Basis';    
        font-weight: 400;
        src: url('/assets/fonts/basis-grotesque-regular.woff2') format('woff2'), 
        url('/assets/fonts/basis-grotesque-regular.woff') format('woff'), 
        url('/assets/fonts/basis-grotesque-regular.otf') format('otf');    
    }
    
    @font-face {
        font-family: 'Basis';
        font-weight: 500;
        src: url('/assets/fonts/basis-grotesque-medium.woff2') format('woff2'), 
        url('/assets/fonts/basis-grotesque-medium.woff') format('woff'),
        url('/assets/fonts/basis-grotesque-medium.otf') format('otf');    
    }  
    
    @font-face {
        font-family: 'Basis';
        font-weight: 700;
        src: url('/assets/fonts/basis-grotesque-bold.woff2') format('woff2'),
        url('/assets/fonts/basis-grotesque-bold.woff') format('woff'),
        url('/assets/fonts/basis-grotesque-bold.otf') format('otf');    
    }

    @font-face {
        font-family: 'LaundryGothic';
        font-weight: 400;
        src: url('/assets/fonts/LaundryGothic-Regular.otf') format('opentype');
    }

    @font-face {
        font-family: 'LaundryGothic';
        font-weight: 700;
        src: url('/assets/fonts/LaundryGothic-Bold.otf') format('opentype');
    }

    .lb {
        display:block;
    }

    .ir {
        zoom: -10;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 1px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-indent: -99999px;
        color: transparent;   
				pointer-events: none;      
    }

    .hide {
        margin:0;
        padding:0;
        border:0;
        font-size:0;
        line-height:0;
        color:transparent;
        overflow:hidden;
        background:transparent;
    }

    .video-wrapper {
        position: relative;
        width: 100%;
        font-size: 0;
        line-height: 0;
    
        video {
          width: 100%;
          // ios 하얀 배경 비디오 테두리 라인 삭제 
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
        }        
    }

    .font__pretendard {
        font-family: Pretendard, sans-serif;
    }

    .font__basis {
        font-family: Basis, sans-serif;
    }

    .scroll-disabled {
        overflow: hidden;
    }
`;
